.card {
    width: 100px;
    height: 150px;
    perspective: 1000px;
    cursor: pointer;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .card.flipped {
    transform: rotateY(180deg);
  }
  
  .card .card-back {
    transform: rotateY(180deg);
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  }
  
  .card .card-back p {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 12px;
    
  }
  
  @media (max-width: 768px) {
    .card {
      width: 80px;
      height: 120px;
    }
  }
.card {
    width: 100px;
    height: 150px;
    perspective: 1000px;
    cursor: pointer;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
  .card p {
    margin: 0;
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }