body {
  background-color: #f9f9f9;
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
}

.App {
  text-align: center;
  margin-top: 50px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.card {
  width: 100px;
  height: 100px;
  perspective: 1000px;
  cursor: pointer;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  backface-visibility: hidden;
  transform: rotateY(0);
  transition: transform 0.3s ease-in-out;
}

.card.flipped img {
  transform: rotateY(180deg);
}

.scoreboard {
  margin-top: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.best-score {
  color: #007bff;
}

.scoreboard {
  margin-top: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.best-score {
  color: #007bff;
}

.instructions {
  margin-top: 20px;
  font-size: 16px;
  font-style: italic;
  color: #555;
}

body {
  background-image: url('/1919684.webp'); /* Replace with the path to your beach background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.App {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
}

.scoreboard {
  margin-top: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.best-score {
  color: #007bff;
}

.instructions {
  margin-top: 20px;
  font-size: 16px;
  font-style: italic;
  color: #555;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Adjust font for better readability */
body {
  font-family: 'Open Sans', sans-serif;
}
